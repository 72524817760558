import { Stack, Grid, TextField, Typography } from "@mui/material";
import PhotoBox from "../photo-box-component";
import MultilineField from "../fields/multiline-field";
import { useEffect, useState } from "react";

const FormHeader = ({ title, setTitle, minioId, setMinioId, setFormData, description, setDescription, label, inputProps, readOnly = false, disabled = false, mandatory = false, extraOptions }) => {
    const [headerField, setHeaderField] = useState(title)
    useEffect(()=>{setHeaderField(title)}, [title])

    return (
        <Stack spacing={5}>
            {readOnly ? <Typography fontSize={"24px"} fontWeight={600}>{title}</Typography>:
                <TextField
                    id={label}
                    label={`${label}${mandatory ? "*" : ""}`}
                    variant="outlined"
                    value={headerField ?? ""}
                    inputProps={inputProps}
                    disabled={readOnly || disabled}
                    onChange={(e) => setHeaderField(e.currentTarget.value)}
                    onBlur={() => setTitle(headerField)}
                />}
            <Grid container>
                <Grid item xs={5}>
                    <PhotoBox minioId={minioId} setMinioId={setMinioId} setFormData={setFormData} readOnly={readOnly} disabled={disabled} />
                </Grid>
                <Grid item xs={7}>
                    <MultilineField
                        value={description ?? ""}
                        setValue={setDescription}
                        label={"Description"}
                        id="club-description"
                        readOnly={readOnly}
                        disabled={disabled}
                        mandatory={mandatory}
                    />
                    {extraOptions}
                </Grid>
            </Grid>
        </Stack>
    );
};

export default FormHeader;