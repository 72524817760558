import { keys } from "ramda"
import dayjs from "dayjs"

export const selectCurrentClubId = (state) => state.appSlice.currentClubId

export const selectClubById = (clubId) => (state) => state.appSlice.userClubs[clubId]

export const selectCurrentClub = (state) => state.appSlice.userClubs[state.appSlice.currentClubId]

export const selectCurrentClubName = (state) => selectCurrentClub(state)?.name

export const selectCurrentClubRole = (state) => selectCurrentClub(state)?.role

export const selectClubs = (state) => state.appSlice.userClubs

export const selectUserUuid = (state) => state.appSlice.userUuid

export const selectUserFirstName = (state) => state.appSlice.userFirstName

export const selectUserLastName = (state) => state.appSlice.userFirstName

export const selectUserDateOfBirth = (state) => state.appSlice.userDateOfBirth

export const selectUserContactNumber = (state) => state.appSlice.userContactNumber

export const selectUserAddress = (state) => state.appSlice.userAddress

export const selectUserEmergencyContact = (state) => state.appSlice.userEmergencyContact

export const selectUserFullName = (state) => `${state.appSlice.userFirstName} ${state.appSlice.userLastName}`

export const selectUserEamil = (state) => state.appSlice.userEmail

export const selectLoadingData = (state) => state.appSlice.loadingData

export const selectUserPersonId = (state) => state.appSlice.userPersonId

export const selectUserMemberId = (state) => selectCurrentClub(state)?.memberId

export const selectUserOnboarded = (state) => selectCurrentClub(state)?.onboarded

export const selectUserPictureId = (state) => state.appSlice.userPictureId

export const selectActivityFromCache = (activityId) => (state) => state.appSlice.notificationCache.activities[activityId]

export const selectEventFromCache = (eventId) => (state) => state.appSlice.notificationCache.events[eventId]

export const selectCachedActivityList = (state) => keys(state.appSlice.notificationCache.activities)
export const selectCachedEventList =  (state) => keys(state.appSlice.notificationCache.events)

export const selectUnreadNotificationCount = (state) => state.appSlice.unreadNotificationCount

export const selectEditMemberCache = (state) => state.appSlice.editMemberCache

export const selectEditMemberCacheInUse = (state) => state.appSlice.editMemberCacheInUse

export const selectMemberQuals = (state) => state.appSlice.currentClubMemberQuals

export const selectCachedDate = (state) => dayjs(state.appSlice.dateCache);