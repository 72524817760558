import { Box, Grid, Stack, Typography } from "@mui/material"
import CardContainer from "../../components/card-container"
import FieldLabel from "../../components/fields/field-label"
import { blueButtonColor } from "../../styles/style-constants"
import { useSelector } from "react-redux"
import { selectCurrentClubName, selectUserEamil } from "../../redux/selectors"
import FormField from "../../components/fields/form-field"
import PhotoBox from "../../components/photo-box-component"
import DatePickerField from "../../components/fields/date-picker-field"
import GoogleMapsField from "../../components/fields/google-maps-field"
import GenderField from "../../components/fields/gender-field"
import IndigenousField from "../../components/fields/indigenous-field"
import AccessNeedsField from "../../components/fields/access-needs-field"
import MultilineField from "../../components/fields/multiline-field"
import BooleanField from "../../components/fields/boolean-field"
import { useEffect } from "react"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathGetClub } from "../../utils/endpoint-paths"
import PasswordField from "../../components/fields/password-field"
import dayjs from 'dayjs'



const InitialOnboardingPageOne = ({
    userUuid,
    clubId,
    isMobile,
    firstName,
    lastName,
    preferredName,
    dateOfBirth,
    gender,
    indigenous,
    contactNumber,
    address,
    emergencyContactName,
    emergencyContactNumber,
    accessNeeds,
    needsDetails,
    needsPrivate,
    password,
    confirmPassword,
    validPassword,
    imageId,
    clubImageId,
    passwordsMatch,
    setFirstName,
    setLastName,
    setPreferredName,
    setDateOfBirth,
    setGender,
    setIndigenous,
    setContactNumber,
    setAddress,
    setEmergencyContactName,
    setEmergencyContactNumber,
    setAccessNeeds,
    setNeedsDetails,
    setNeedsPrivate,
    setPassword,
    setConfirmPassword,
    setValidPassword,
    setImageId,
    setImageFormData,
    setClubImageId
}) => {

    const { generateEndpoint, doGet } = useApiUtilsContext()


    const clubName = useSelector(selectCurrentClubName)


    const getClubEndpoint = generateEndpoint(apiPathGetClub(clubId))

    const email = useSelector(selectUserEamil)

    
    useEffect(() => {

        const getDetails = async () => {

            const clubResponse = await doGet({ endpoint: getClubEndpoint })

            setClubImageId(clubResponse?.data?.details?.pictureId)


        }

        if (clubId) {
            getDetails()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[clubId])


    return <CardContainer padding="20px" marginTop="15px">

        <Grid container columns={3} justifyContent={'center'}>
            <Grid item sm={2} xs={3}>

                <Stack direction={"column"} spacing={3}>
                    <Typography fontWeight={500} fontSize="24px" lineHeight="29.26px">
                        You have been invited to join {clubName}
                    </Typography>

                    <Typography fontSize={"14px"} color={"#323E59"}>
                        Please complete the following onboarding form to successfully join the club.
                    </Typography>

                    <Typography lineHeight={"12.19px"} fontSize={"10px"} color={"#323E59"}>
                        <span style={{ color: "#F41616" }}>*</span>Required
                    </Typography>

                    <Box marginTop={8} />

                    <FieldLabel label={"Login Details:"} color={blueButtonColor} />
                    <FieldLabel label={"Email Address"} />
                    <Typography color="#0000008A" fontSize={"14px"}>
                        {email} (not able to be changed during onboarding)
                    </Typography>

                </Stack>

            </Grid>
            {!isMobile &&
                <Grid item xs={1}>
                    <PhotoBox readOnly={true} label="" minioId={clubImageId} />
                </Grid>}

        </Grid>

        <Grid container columns={2} rowSpacing={5} marginTop={5}>
            <Grid item xs={2}>
                <PasswordField label={"Set new password"}
                    value={password}
                    setValue={setPassword}
                    valid={validPassword}
                    setValid={setValidPassword}
                    validatePassword
                />
            </Grid>
            <Grid item xs={2}>
                <PasswordField
                    label={"Repeat new password"}
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    valid={passwordsMatch}
                    errorMessage="Passwords must match!"
                />
            </Grid>
            <Grid item xs={2}>
                <FieldLabel label={"Personal Information:"} color={blueButtonColor} />
            </Grid>
            <Grid item xs={2}>
                <PhotoBox label={"upload profile picture"} minioId={imageId} setMinioId={setImageId} setFormData={setImageFormData} />
            </Grid>

            <Grid item sm={1} xs={2}>
                <FormField label={"First name"} value={firstName} setValue={setFirstName} mandatory />
            </Grid>
            <Grid item sm={1} xs={2}>
                <FormField label={"Last name"} value={lastName} setValue={setLastName} mandatory />
            </Grid>
            <Grid item xs={2}>
                <FormField label={"Preferred name"} value={preferredName} setValue={setPreferredName} />
            </Grid>
            <Grid item xs={2}>
                <DatePickerField label={"DOB"} maxDate={dayjs()} value={dateOfBirth} setValue={setDateOfBirth} />
            </Grid>
            <Grid item xs={2}>
                <GenderField value={gender} setValue={setGender} />
            </Grid>
            <Grid item xs={2}>
                <IndigenousField value={indigenous} setValue={setIndigenous} />
            </Grid>
            <Grid item xs={2}>
                <FieldLabel label={"Contact Information:"} color={blueButtonColor} />
            </Grid>
            <Grid item xs={2} sm={1}>
                <FormField label={"Contact Number"} value={contactNumber} setValue={setContactNumber} mandatory />
            </Grid>
            <Grid item xs={2} sm={1}>
                <GoogleMapsField label={"Address"} value={address} setValue={setAddress} mandatory />
            </Grid>
            <Grid item xs={2} sm={1}>
                <FormField label={"Emergency Contact Name"} value={emergencyContactName} setValue={setEmergencyContactName} mandatory />
            </Grid>
            <Grid item xs={2} sm={1}>
                <FormField label={"Emergency Contact Number"} value={emergencyContactNumber} setValue={setEmergencyContactNumber} mandatory />
            </Grid>
            <Grid item xs={2}>
                <FieldLabel label={"Access Needs:"} color={blueButtonColor} />
                <Typography fontWeight={400} fontSize={"14px"} fontStyle={"italic"}>
                    Please note, details about your access needs will only be shared with Club Admins as a means of ensuring that the club accommodates for them to the best of our abilities. Unless otherwise stated, our information will not be shared with other members.
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <AccessNeedsField value={accessNeeds} setValue={setAccessNeeds} />
            </Grid>
            <Grid item xs={2}>
                <MultilineField
                    value={needsDetails}
                    setValue={setNeedsDetails}
                    label="Additional Details"
                    width="60ch"
                />
            </Grid>
            <Grid item xs={2}>
                <BooleanField
                    value={needsPrivate}
                    setValue={setNeedsPrivate}
                    trueLabel="No"
                    falseLabel="Yes"
                    flipOptions
                    maxWidth={"60ch"}
                    label={"Would you like other club members to know about your access needs?"}
                />
            </Grid>
        </Grid>

    </CardContainer>

}


export default InitialOnboardingPageOne