import { Grid, Stack, Typography } from "@mui/material"
import { DayHeader } from "./day-header"
import { equals, filter, map, isEmpty } from "ramda"
import dayjs from "dayjs"
import StatusCircleComponent from "../../components/status-circle-component"
import { newFormEntityId, statusAccepted, statusAssigned, statusVacant } from "../../utils/constants"
import getActivityStatusCounts, { getEventStatusCounts } from "../../utils/helper-functions/get-activity-status-counts"
import { formatTime, parseBackendDatetime, timeFromDateTime } from "../../utils/helper-functions/datetime-functions"
import { editEventRoute } from "../../utils/page-routes"
import ButtonComponent from "../../components/button-component"
import { Add } from "@mui/icons-material"
import { useIsAdmin } from "../../utils/user-hooks"
import useDoNavigate from "../../utils/do-navigate"


const EventItem = ({ event }) => {
  
    const doNavigate = useDoNavigate()
    
    const counts = getActivityStatusCounts(event?.activities ?? [])
    return <Grid
        container
        columns={13}
        onClick={() => {
            doNavigate(editEventRoute, { target: event?.id })
        }}
        sx={{
            ":hover": {
                background: "#D1D1D1",
                cursor: "pointer"
            },
            background: "#F1F1F1",
            padding: 1,
            marginTop: "4px",
        }}
    >

        <Grid item xs={2} width="100%" fontWeight={700} textAlign={"center"}>
            {formatTime(timeFromDateTime(event?.start))}
        </Grid>
        <Grid item xs={1} width="100%">
            {event?.eventTypeName}
        </Grid>
        <Grid item xs={8} width="100%">
            {event?.details?.name}
        </Grid>
        <Grid item xs={2} width="100%">
            <Stack direction={"row"} width="100%" spacing={1}>
                <StatusCircleComponent status={statusVacant} count={counts[statusVacant] ?? 0} />
                <StatusCircleComponent status={statusAssigned} count={counts[statusAssigned] ?? 0} />
                <StatusCircleComponent status={statusAccepted} count={counts[statusAccepted] ?? 0} />
            </Stack>
        </Grid>

    </Grid>
}


const DayEventView = ({ selectedDate, events }) => {

  const doNavigate = useDoNavigate()

    const isAdmin = useIsAdmin()
    const getEventDate = (event) => parseBackendDatetime(event?.start).format('YYYY-MM-DD')

    const filteredEvents = filter((event) => equals(dayjs(selectedDate).format("YYYY-MM-DD"), getEventDate(event)))(events)

    const totalCounts = getEventStatusCounts(filteredEvents)

    return <Grid
        container
        columns={1}
        width={"full"}
        justifyContent={"space-evenly"}
    >

        <DayHeader day={selectedDate.format("dddd")} />



        <Stack direction={"column"} width={"100%"} alignItems={"center"} marginX={1}>
            <Stack direction={"row"} spacing={1} margin={2}>
                <StatusCircleComponent size={"50px"} fontSize="18px" status={statusVacant} count={totalCounts[statusVacant] ?? 0} />
                <StatusCircleComponent size={"50px"} fontSize="18px" status={statusAssigned} count={totalCounts[statusAssigned] ?? 0} />
                <StatusCircleComponent size={"50px"} fontSize="18px" status={statusAccepted} count={totalCounts[statusAccepted] ?? 0} />
            </Stack>

            {isEmpty(filteredEvents) && <Stack direction={"column"} alignItems={"center"} gap={1}>
                <Typography>No events today!</Typography>

            </Stack>}

            {map((event) => <EventItem key={event?.id} event={event} />)(filteredEvents)}

            {isAdmin &&
                <Stack direction={"column"} alignItems={"center"} paddingTop={2}>
                    <ButtonComponent title={"Create New Event"} icon={<Add />} onClick={() => {
                        doNavigate(editEventRoute, { target: newFormEntityId, date: dayjs(selectedDate).toISOString() })
                    }} />
                </Stack>
            }

        </Stack>



    </Grid>


}

export default DayEventView