import { ButtonBase, Container, Drawer, Grid, IconButton, Stack, Typography } from "@mui/material"
import { drawerWidth, primaryColor } from "../../styles/style-constants"
import { equals, keysIn, map } from "ramda"
import { Edit, Group } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { clubDetailsRoute, dashboardRoute } from "../../utils/page-routes"
import { useDispatch, useSelector } from "react-redux"
import { selectClubs, selectCurrentClub, selectUserUuid } from "../../redux/selectors"
import { setCurrentClubId } from "../../redux/app-slice"
import { useIsAdmin } from "../../utils/user-hooks"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { apiPathGetMemberQuals, apiPathPutUser } from "../../utils/endpoint-paths"
import { setCurrentClubMemberQuals } from "../../redux/app-slice"
import useDoNavigate from "../../utils/do-navigate"

const ClubButton = ({ onClose, clubId }) => {

    const clubsList = useSelector(selectClubs)
    const currentClub = useSelector(selectCurrentClub)
    const dispatch = useDispatch()
    const dispatchCurrentClubId = clubId => dispatch(setCurrentClubId(clubId))
    const dispatchMemberQual = (qual) => dispatch(setCurrentClubMemberQuals(qual))
    const { doGet, generateEndpoint, doPut } = useApiUtilsContext();


    const doNavigate = useDoNavigate();

    const isAdmin = useIsAdmin()
    const club = clubsList[clubId] ?? {}
    const currentClubId = currentClub.id
    const memberId = currentClub?.memberId
    // Gotta cast to a string for some reason :|
    const isCurrent = equals(`${currentClubId}`)(clubId)
    const userUuid = useSelector(selectUserUuid);
    const getQualificationsEndPoint = generateEndpoint(apiPathGetMemberQuals(memberId))
    const updateUserEndpoint = (uuid) => generateEndpoint(apiPathPutUser(uuid));
    
    const onClick = async () => {
        if(currentClubId === clubId) return;
        dispatchCurrentClubId(clubId)
        const qualResponse = await doGet({ endpoint: getQualificationsEndPoint })
        // update persons lastclubid
        const userReponse = await doPut({ endpoint: updateUserEndpoint(userUuid), body: { lastClubId: clubId } })
        dispatchMemberQual(qualResponse?.data);
        doNavigate(dashboardRoute);

        onClose()
    }

    return <Stack direction="row" spacing={2} width={"100%"} justifyContent={'space-between'}>
        <ButtonBase sx={{padding:1, borderRadius:"5px"}} onClick={onClick}>
            <Typography fontWeight={700}
                textTransform={"uppercase"}
                fontSize={"14px"}
                lineHeight={"17px"}
                letterSpacing={"2px"}
                sx={{
                    opacity: isCurrent ? 1.0 : 0.4
                }}>
                {club?.name}
            </Typography>

        </ButtonBase>
        <IconButton 
            component={Link}
            to={clubDetailsRoute}
            onClick={onClick}
            sx={{
                color:"white",
                opacity: isCurrent ? 1.0 : 0.4,
                borderRadius:"100px"
            }}
        >
            {isAdmin ? <Edit /> : <Group/>}
        </IconButton>
    </Stack>
}

const ClubDrawerComponent = ({ isOpen, onClose }) => {

    const clubsList = useSelector(selectClubs)

    return <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={onClose}
    >
        <Container sx={{ background: primaryColor, height: "100%", color: "white" }}>
            <Grid container alignItems={'center'} direction={"column"} width={drawerWidth}>

                <Grid item xs={12}>
                    <Typography fontWeight={"bold"} textAlign={"center"} variant="h6" paddingY={2}>
                        Select a club
                    </Typography>
                </Grid>

                {map((clubId) => <Grid key={clubId} item xs={12} width="100%">
                    <ClubButton onClose={onClose} clubId={clubId} />
                </Grid>)(keysIn(clubsList))}

            </Grid>

        </Container>

    </Drawer>

}

export default ClubDrawerComponent