import { createSlice } from "@reduxjs/toolkit";
import { has, reduce } from "ramda";
import { headKeyIn, isNotEmpty } from "../utils/functionals";
import dayjs from "dayjs";

// Different kind of reduce to a reducer!
const reduceClubsMap =  reduce((acc, club) => {
    return {
        ...acc, 
        [club.clubId]: {
            id: club.clubId, 
            role: club.userTypes[0], 
            name: club?.clubName, 
            memberId: club?.id, 
            onboarded: club?.onboarded
        } 
    }
},{})


export const appSlice = createSlice({
    name:"appSlice",
    initialState: {
        loadingData: true,

        currentClubId: undefined,
        userPersonId: undefined,
        userUuid: undefined,
        userEmail: undefined,
        userFirstName : undefined,
        userLastName: undefined,
        userDateOfBirth: undefined,
        userContactNumber: undefined,
        userAddress: undefined,
        userImageId: undefined,
        currentClubMemberQuals: [],
        userEmergencyContact: {},
        userClubs: {},
        notificationCache: {
            events: {},
            activities: {}
        },
        unreadNotificationCount: 0,
        editMemberCache: {},
        editMemberCacheInUse: false,
        dateCache: dayjs().format("YYYY-MM-DD"),
    },
    reducers: {
        setUserDetails: (state, action) => {
            if (action?.payload?.uuid) {
                state.userUuid = action?.payload?.uuid
            }
            state.userEmail = action?.payload?.email
            state.userFirstName = action?.payload?.firstName
            state.userLastName = action?.payload?.lastName
            state.userDateOfBirth = action?.payload?.dateOfBirth
            state.userContactNumber = action?.payload?.mobile
            state.userAddress = action?.payload?.location
            state.userEmergencyContact = action?.payload?.emergencyContact
            state.userPersonId = action?.payload?.id
            state.userPictureId = action?.payload?.pictureId
        },
        setCurrentClubId: (state, action) => {
            state.currentClubId = action?.payload
        },
        setUserClubs: (state, action) => {
            state.userClubs = reduceClubsMap(action?.payload ?? {})
        },
        setCurrentClubMemberQuals: (state, action) => {
            state.currentClubMemberQuals = action?.payload.map((qual) => qual?.type)
        },
        updateUserClub: (state, action) => {
            const club = action?.payload ?? {}
            const clubId = club?.id
            if (has(clubId)(state.userClubs)) {
                state.userClubs[clubId] = {
                    ...state.userClubs[clubId],
                    name: club?.details?.name,
                    ...club,
                }
            }
        },
        updateLoadingData: (state, action) => {
            state.loadingData = action?.payload ?? true
        },
        addToActivityCache: (state, action) => {
            state.notificationCache.activities[action?.payload?.id] = action?.payload
        },
        addToEventsCache: (state, action) => {
            state.notificationCache.events[action?.payload?.id] = action.payload
        },
        updateEditMembersCache: (state, action) => {
            state.editMemberCache = action?.payload
            state.editMemberCacheInUse = true
        },
        clearEditMembersCache: (state) => {
            state.editMemberCache = {}
            state.editMemberCacheInUse = false
        },
        updateUnreadNotificationCount: (state, action) => {
            state.unreadNotificationCount = action?.payload
        },
        setDateCache: (state, action) => {
            state.dateCache = action?.payload
        }
    }
})

export const {
    setFormEntityId, 
    setUserDetails, 
    setUserClubs, 
    setCurrentClubId, 
    updateUserClub, 
    updateLoadingData,
    addToActivityCache,
    addToEventsCache,
    updateEditMembersCache,
    clearEditMembersCache,
    updateUnreadNotificationCount,
    setCurrentClubMemberQuals,
    setDateCache,
} = appSlice.actions
const appSliceReducer = appSlice.reducer
export default appSliceReducer